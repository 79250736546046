import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Typography, Divider } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import { Form, Field } from 'react-final-form';
import { useStyles } from './styles';
import { EntityService } from 'core/api/services/lookUp';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import { toast } from 'react-toastify';
import PhoneNumber from 'core/libs/core-ui/components/Input/PhoneNumber';
import Lookup from 'core/libs/core-ui/components/Lookup';
import { ExperienceResourceService } from 'core/api/services/ExperienceInfo/expResource';
import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';
import { LookUpResource } from 'core/libs/core-ui/components/Lookup/Entities';

const ResourceToExperienceAddModal = ({
    experienceId,
    getResources,
    handleSubmit,
    onClose,
    ...props
}) => {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const [resourceSelected, setResourceSelected] = useState('');

    const onFormSubmit = async (values) => {
        const newData = [
            {
                resourceId: resourceSelected,
                resourceCategoryId: resourceSelected,
                notificationEmail: false,
                notificationWhatsapp: false,
            },
        ];
        try {
            const result = await handleSubmit(experienceId, newData);
            if (result) {
                toast.success(translate('resource.resource_success'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            } else {
                toast.error(translate('resource.resource_error'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
            onClose();
        } catch (error) {
            console.error(translate('resource.resource_error'), error);
            toast.error(translate('resource.resource_error'), {
                position: 'top-right',
                theme: 'colored',
            });
        }

        // let methodResource;
        // if (resourceSelected && Object.keys(resourceSelected).length > 0) {
        //     methodResource = 'updateResource';
        // } else {
        //     methodResource = 'postResource';
        // }
        // const formData = {
        //     name: name,
        //     resourceCategoryId: type,
        //     email: email,
        //     mobilePhone: mobilePhone,
        // };
        // ResourceService.postCategory(formData)
        //     .then((result) => {
        //         if (result) {
        //             toast.success(translate('resource.resource_success'), {
        //                 position: 'top-right',
        //                 theme: 'colored',
        //             });
        //             onClose();
        //             onPostSuccess();
        //         } else {
        //             console.error(translate('resource.resource_error'), result);
        //             toast.error(translate('resource.resource_error'), {
        //                 position: 'top-right',
        //                 theme: 'colored',
        //             });
        //         }
        //     })
        //     .catch((error) => {
        //         console.error('Error al enviar el formulario:', error);
        //         toast.error(translate('resource.resource_error'), {
        //             position: 'top-right',
        //             theme: 'colored',
        //         });
        //     });
    };
    const required = (value) => {
        return value ? undefined : 'Required';
    };

    const handleSelectionChange = (selectedValue) => {
        setResourceSelected(selectedValue);
    };

    return (
        <>
            <Typography variant="h3" className={classes.titleDialog}>
                {translate('resource.add_resource')}
            </Typography>
            <Divider />
            <Grid>
                <Form
                    onSubmit={onFormSubmit}
                    initialValues={{}}
                    render={({
                        input,
                        meta,
                        handleSubmit,
                        valid,
                        pristine,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid>
                                <LookUpResource
                                    value={resourceSelected}
                                    entityService={EntityService}
                                    onChange={(newValue) =>
                                        handleSelectionChange(newValue)
                                    }
                                />

                                {/* <Field
                                    name="checkboxFieldName"
                                    component={CheckboxComponent}
                                    type="checkbox" 
                                    labelComponent={translate(
                                        'Email Notificacion'
                                    )}
                                    onChange={()=> {
                                        console.log('work')
                                    }}
                                />

                                <Field
                                    name="checkboxFieldName"
                                    component={CheckboxComponent}
                                    type="checkbox" 
                                    labelComponent={translate(
                                        'Whatsapp Notificacion'
                                    )}
                                    onChange={()=> {
                                        console.log('work')
                                    }}
                                /> */}

                                <Box className={classes.buttonContainer}>
                                    <Button
                                        disabled={!valid}
                                        type="submit"
                                        className={classes.createButton}
                                    >
                                        {translate(
                                            'trip.create.buttons.add_co_triper'
                                        )}
                                    </Button>
                                </Box>
                            </Grid>
                        </form>
                    )}
                ></Form>
            </Grid>
        </>
    );
};

export default ResourceToExperienceAddModal;
