import React, { useState } from 'react';
import Lookup from '../..';

const LookUpResource = ({ onChange, value, entityService, ...props }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleSelectionChange = (newValue) => {
        setSelectedValue(newValue);
        onChange(newValue);
    };

    return (
        <Lookup
            label="Resource Name"
            entity="resource"
            value={selectedValue}
            displayValue="name"
            selectValue="id"
            search="name"
            onChange={handleSelectionChange}
            menuItemProps={{
                style: {
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                },
            }}
            entityService={entityService}
            {...props}
        />
    );
};

export default LookUpResource;
