import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Typography, Divider } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import { Form, Field } from 'react-final-form';
import { useStyles } from './styles';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import { toast } from 'react-toastify';
import PhoneNumber from 'core/libs/core-ui/components/Input/PhoneNumber';
import Lookup from 'core/libs/core-ui/components/Lookup';
import { ExperienceResourceService } from 'core/api/services/ExperienceInfo/expResource';
import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';
import { useAuth } from 'core/contexts/auth';
import { LookUpResourceCategory } from 'core/libs/core-ui/components/Lookup/Entities';
import { EntityService } from 'core/api/services/lookUp';

const CategoryToExperienceAddModal = ({
    experienceId,
    getResources,
    handleSubmit,
    onClose,
    ...props
}) => {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const [categorySelected, setCategorySelected] = useState('');

    const onFormSubmit = async (values) => {
        const newData = [
            {
                resourceId: categorySelected,
                resourceCategoryId: categorySelected,
                notificationEmail: false,
                notificationWhatsapp: false,
            },
        ];
        try {
            const result = await handleSubmit(experienceId, newData);
            if (result) {
                toast.success(translate('resource.resource_success'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            } else {
                toast.error(translate('resource.resource_error'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
            onClose();
        } catch (error) {
            console.error(translate('resource.resource_error'), error);
            toast.error(translate('resource.resource_error'), {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    const required = (value) => {
        return value ? undefined : 'Required';
    };

    const handleSelectionChange = (selectedValue) => {
        setCategorySelected(selectedValue);
    };

    return (
        <>
            <Typography variant="h3" className={classes.titleDialog}>
                {translate('resource.add_category')}
            </Typography>
            <Divider />
            <Grid>
                <Form
                    onSubmit={onFormSubmit}
                    initialValues={{}}
                    render={({
                        input,
                        meta,
                        handleSubmit,
                        valid,
                        pristine,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid>
                                <LookUpResourceCategory
                                    value={categorySelected}
                                    entityService={EntityService}
                                    onChange={(newValue) =>
                                        handleSelectionChange(newValue)
                                    }
                                />

                                {/* <Field
                                    name="checkboxFieldName"
                                    component={CheckboxComponent}
                                    type="checkbox"
                                    labelComponent={translate(
                                        'Email Notificacion'
                                    )}
                                    onChange={(value) => {
                                        setEmailNotificationCheckBox(value);
                                    }}
                                    disabled={!categorySelected?.email}
                                />

                                <Field
                                    name="checkboxFieldName"
                                    component={CheckboxComponent}
                                    type="checkbox"
                                    labelComponent={translate(
                                        'Whatsapp Notificacion'
                                    )}
                                    onChange={(value) => {
                                        setwhatsappNotification(value);
                                    }}
                                    disabled
                                /> */}

                                <Box className={classes.buttonContainer}>
                                    <Button
                                        disabled={!valid}
                                        type="submit"
                                        className={classes.createButton}
                                    >
                                        {translate(
                                            'trip.create.buttons.add_co_triper'
                                        )}
                                    </Button>
                                </Box>
                            </Grid>
                        </form>
                    )}
                ></Form>
            </Grid>
        </>
    );
};

export default CategoryToExperienceAddModal;
